import React from "react";
import './fundos.css';

import AnuncioOferta from '../assets/docs/FIDC Voltz I Anúncio de Início.pdf';

const Bravo = () => {
  return (

    <div id="main-container">
      <div id="secao-comunicados">
        <h1 className="text-3xl font-bold text-center">FIDC Voltz Supply I</h1>
        <h2 className="text-3xl font-bold text-center">Comunicados</h2>

        {/* Conteúdo da seção centralizada à esquerda */}
        <div id="container-comunicados">
          <div>
            <p><strong className="text-2xl">14 de Outubro de 2024</strong></p>
            <br />
            <a
              href={AnuncioOferta}
              download
              target="_blank"
              rel="noreferrer"
              className="button"
            >
              Anúncio de Oferta
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Bravo;